import React, { SVGProps } from 'react';

export const GithubLogo: React.FC<
  React.PropsWithChildren<SVGProps<SVGSVGElement>>
> = (props) => (
  <svg
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Github</title>
    <g clipPath="url(#clip0_636_23130)">
      <path
        d="M12.6 0C5.97398 0 0.599976 5.373 0.599976 12C0.599976 17.302 4.03798 21.8 8.80698 23.387C9.40598 23.498 9.59998 23.126 9.59998 22.81V20.576C6.26198 21.302 5.56698 19.16 5.56698 19.16C5.02098 17.773 4.23398 17.404 4.23398 17.404C3.14498 16.659 4.31698 16.675 4.31698 16.675C5.52198 16.759 6.15598 17.912 6.15598 17.912C7.22598 19.746 8.96298 19.216 9.64798 18.909C9.75498 18.134 10.066 17.604 10.41 17.305C7.74498 17 4.94298 15.971 4.94298 11.374C4.94298 10.063 5.41198 8.993 6.17898 8.153C6.05498 7.85 5.64398 6.629 6.29598 4.977C6.29598 4.977 7.30398 4.655 9.59698 6.207C10.554 5.941 11.58 5.808 12.6 5.803C13.62 5.808 14.647 5.941 15.606 6.207C17.897 4.655 18.903 4.977 18.903 4.977C19.556 6.63 19.145 7.851 19.021 8.153C19.791 8.993 20.256 10.064 20.256 11.374C20.256 15.983 17.449 16.998 14.777 17.295C15.207 17.667 15.6 18.397 15.6 19.517V22.81C15.6 23.129 15.792 23.504 16.401 23.386C21.166 21.797 24.6 17.3 24.6 12C24.6 5.373 19.227 0 12.6 0Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_636_23130">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.599976)"
        />
      </clipPath>
    </defs>
  </svg>
);
