import React, { SVGProps } from 'react';

export const GoogleLogo: React.FC<
  React.PropsWithChildren<SVGProps<SVGSVGElement>>
> = (props) => (
  <svg
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Google</title>
    <g clipPath="url(#clip0_636_23102)">
      <path
        d="M24.545 12.27C24.545 11.48 24.475 10.73 24.355 10H13.055V14.51H19.525C19.235 15.99 18.385 17.24 17.125 18.09V21.09H20.985C23.245 19 24.545 15.92 24.545 12.27Z"
        fill="#4285F4"
      />
      <path
        d="M13.055 24C16.295 24 19.005 22.92 20.985 21.09L17.125 18.09C16.045 18.81 14.675 19.25 13.055 19.25C9.92497 19.25 7.27497 17.14 6.32497 14.29H2.34497V17.38C4.31497 21.3 8.36497 24 13.055 24Z"
        fill="#34A853"
      />
      <path
        d="M6.32499 14.29C6.07499 13.57 5.94499 12.8 5.94499 12C5.94499 11.2 6.08499 10.43 6.32499 9.71V6.62H2.34499C1.52499 8.24 1.05499 10.06 1.05499 12C1.05499 13.94 1.52499 15.76 2.34499 17.38L6.32499 14.29Z"
        fill="#FBBC05"
      />
      <path
        d="M13.055 4.75C14.825 4.75 16.405 5.36 17.655 6.55L21.075 3.13C19.005 1.19 16.295 0 13.055 0C8.36497 0 4.31497 2.7 2.34497 6.62L6.32497 9.71C7.27497 6.86 9.92497 4.75 13.055 4.75Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_636_23102">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.799988)"
        />
      </clipPath>
    </defs>
  </svg>
);
