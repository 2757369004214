import React, { SVGProps } from 'react';

export const TwitterLogo: React.FC<
  React.PropsWithChildren<SVGProps<SVGSVGElement>>
> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 244.187">
    <title>Twitter</title>
    <path
      d="M94.72 243.18c112.46 0 173.96-93.168 173.96-173.96 0-2.646-.054-5.28-.173-7.903a124.338 124.338 0 0 0 30.498-31.66c-10.955 4.87-22.744 8.147-35.11 9.625 12.622-7.569 22.313-19.543 26.885-33.817a122.62 122.62 0 0 1-38.824 14.841C240.8 8.422 224.916.99 207.327.99c-33.764 0-61.144 27.381-61.144 61.132 0 4.798.537 9.465 1.586 13.941-50.815-2.556-95.874-26.886-126.03-63.88a60.977 60.977 0 0 0-8.279 30.73c0 21.212 10.794 39.938 27.208 50.893a60.685 60.685 0 0 1-27.69-7.646c-.009.256-.009.506-.009.78 0 29.61 21.075 54.332 49.051 59.934a61.218 61.218 0 0 1-16.122 2.152c-3.933 0-7.766-.387-11.49-1.103 7.783 24.293 30.354 41.971 57.114 42.465-20.926 16.402-47.287 26.171-75.937 26.171-4.929 0-9.798-.28-14.584-.846 27.06 17.344 59.19 27.464 93.722 27.464"
      fill="#1da1f2"
    />
  </svg>
);
