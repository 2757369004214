import React, { SVGProps } from 'react';

export const FacebookLogo: React.FC<
  React.PropsWithChildren<SVGProps<SVGSVGElement>>
> = (props) => (
  <svg
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Facebook</title>
    <g clipPath="url(#clip0_636_23113)">
      <path
        d="M10.42 23.88C4.71996 22.86 0.399963 17.94 0.399963 12C0.399963 5.4 5.79996 0 12.4 0C19 0 24.4 5.4 24.4 12C24.4 17.94 20.08 22.86 14.38 23.88L13.72 23.34H11.08L10.42 23.88Z"
        fill="url(#paint0_linear_636_23113)"
      />
      <path
        d="M17.0799 15.36L17.62 12H14.4399V9.65999C14.4399 8.69999 14.7999 7.97999 16.24 7.97999H17.8V4.91999C16.96 4.79999 16 4.67999 15.16 4.67999C12.4 4.67999 10.4799 6.35999 10.4799 9.35999V12H7.47995V15.36H10.4799V23.82C11.1399 23.94 11.8 24 12.46 24C13.12 24 13.7799 23.94 14.4399 23.82V15.36H17.0799Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_636_23113"
        x1="12.4006"
        y1="23.1654"
        x2="12.4006"
        y2="-0.00442066"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0062E0" />
        <stop offset="1" stopColor="#19AFFF" />
      </linearGradient>
      <clipPath id="clip0_636_23113">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.399963)"
        />
      </clipPath>
    </defs>
  </svg>
);
